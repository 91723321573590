import React from 'react'
import './litleDescription.css'
import muskari from '../images/muskariResize.jpg'
import testVid from '../promoVideos/Ester.mp4'

const litleDescription = () => {
    
  const MuskariLink = () => {
      window.location.href = "/muskari"
    }
    return(
      
      <div>
        <div className="">
          <div  className="vkl">
            <div className="vkl-text" >
                <h3>Viikonloppukurssit</h3>
                <p>Viikonloppukursseja järjetetään kaiken tasoisille musiikkiharrastajille ja harrastamisesta kiinnostuneille aikuisille. 
                  Kurssit pidetään pääsääntöisesti musiikkiopiston tiloissa Järvenpään toimipisteessä osoitteessa Seutulantie 8.</p>
                <br></br>
                <p>Osallistujat ovat yleensä iältään n. 20–65-vuotiaita miehiä ja naisia. Viikonloppukursseilla opetusta on 4 tai 8 tuntia, 
                  ja opetus jakautuu lauantaille ja sunnuntaille. Kurssimaksu 8 tunnin kurssilla on 80 € ja 4 tunnin kurssilla 40 €</p>
                <button onClick={() => window.location.href = "https://kum.fi/viikonloppukurssit" } className="btn-litle" >Katso kurssit</button>
            </div>
              <div className="im-row">
                  <video style={{ width: "100%" , height: "500px", backgroundColor: "black"}} controls>
                  <source src={testVid}></source>
              </video>       
            </div>
            </div>
        </div>
          <div className="color2">
            <div className="vkl">
              {/*
              <div className="vkl-img">
              <img src={avoinIm} alt="avoin musiikkiopisto"></img>
              </div>
              */}
              <div className="text-right">
                <h3>Avoin musiikkiopisto</h3>
                <p><strong>Mitä on avoin musiikkiopisto?</strong></p>
                <p>Avoimeen musiikkiopistoon on jatkuva ilmoittautuminen nettisivuillamme. Oppilaat otetaan vapautuville oppilaspaikoille ilmoittautumisjärjestyksessä. Otamme yhteyttä, kun paikka vapautuu. Opetukseen ei ole ikä- tai kotikuntarajoituksia.</p>
                <br></br>
                <p>Avoimessa musiikkiopistossa voit opiskella yksilöopetuksessa, soitinkokeilussa, koulumusikantit- tai musikantit -pienryhmäopetuksessa, viikonloppukursseilla sekä muussa ryhmäopetuksessa esimerkiksi orkesterissa, kuorossa tai bändissä.</p>
                <button  onClick={() => window.open('https://kum.eepos.fi/#/forms', '_blank')} className="btn" >Ilmoittaudu mukaan</button>
              </div>
            </div>
          </div>
            <div className="muskari">
              <div className="muskari-img">
                <img src={muskari} alt="muskari"></img>
              </div>
              <div onClick={() => MuskariLink()} className="muskari-text">
                <h3>Muskari</h3>
                <p><strong>Tarjoa lapselle musiikin maailma!</strong></p>
                <p>Muskari eli musiikkileikkikoulu tarjoaa lapselle oman ihanan harrastuksen, jossa lapsi viihtyy, oppii ja saa elämyksiä. Musiikkikasvatuksella on tutkitusti tärkeä ja merkittävä osa lapsen kielellisen, motorisen, emotionaalisen ja sosiaalisen kehityksen tukemisessa.</p>
                <br></br>
                <p>Opetuksen tavoitteena on kehittää lapsen laulu- ja soittotaitoa, rytmi- ja melodiatajua, kuuntelukykyä sekä musiikillista ilmaisua.</p>
                <p><strong>Tervetuloa tutustumaan muskariin ensimmäisen kerran maksutta!</strong></p> 
              </div>
            </div>
      </div>
    )
}

export default litleDescription;