import React, { useEffect } from 'react';
import './faq2.css';
import Helmet from 'react-helmet';

const Faq2 = () => {

    function toggleAccordion(){
      this.classList.toggle('active');
      this.nextElementSibling.classList.toggle('active');
    
    }
    
    useEffect(() => {
        const items = document.querySelectorAll(".accordion h4");
        items.forEach(item => item.addEventListener('click', toggleAccordion));

    })
    


    return(
      <div class="container">
        <Helmet>
           <title>FAQ</title>
           <meta name="description" content="kum faq tiedot"></meta>
           <link rel="canonical" href="http://kum.fi/faq" />
        </Helmet>
        <br></br>
        <br></br>
        <br></br>

       <h2>FAQ</h2>
       <br></br>
       <h4>Harrastusta harkitsevalle:</h4>
      <div  className="accordion">
        <div className="accordion-item">
         <h4>Minulla on lapsi, joka haluaisi alkaa soittaa jotain soitinta. Mitä vaihtoehtoja teillä on?</h4>
        <div className="content-faq">
          <p>Voit ilmoittautua muskariryhmään, musiikkivalmennukseen, lauluvalmennukseen tai haluamaasi instrumenttiopetukseen. Soitinta valitessa kannattaa tutustua erilaisiin vaihtoehtoihin <a href="/opiskelu">täältä</a> ja valita se, joka lapsesta tuntuu kiinnostavalta. Jotkut soittimet, kuten vaskipuhaltimet, vaativat soittajalta hieman enemmän ikää, ja niistä kannattaa kysyä vielä erikseen musiikkiopistolta. Soittoharrastuksen sopiva aloittamisikä on usein yksilöllinen, eikä tarkkaa ikäsuositusta voi aina määritellä.</p>
        </div>
        </div>
        <div className="accordion-item">
          <h4>Minkä ikäisenä teillä voi aloittaa soittoharrastuksen?</h4>
        <div className="content-faq">
           <p>Musiikkiopistolla voi opiskella kaikki vauvasta vaariin. Musiikkiopistolla on monipuolisia opintopolkuja kaikille musiikista kiinnostuneille. Monet aloittavat musiikkiopistossa vauvana muskarissa ja jatkavat opintoja omille soittotunneille. Musiikki voi olla koko elämän mittainen harrastus musiikkiopistossa.</p>
        </div>
        </div>
        <div className="accordion-item">
          <h4>Kuinka paljon harrastus vie aikaa viikossa?</h4>
        <div className="content-faq">
          <p>Musiikkiopistolla käydään noin 1-3 kertaa viikossa, riippuen oppilaan opintopolusta ja kuinka pitkällä hän on opinnoissa. Kotona harjoitteluun on myös hyvä varata aikaa.</p>
        </div>
        </div>
        <div className="accordion-item">
          <h4>Milloin soittotunti on? Voinko vaikuttaa sen aikaan?</h4>
        <div className="content-faq">
           <p>Soittotunti sovitaan opettajan kanssa lukuvuoden alussa. Valtaosa opetuksesta tapahtuu iltapäivällä tai alkuillalla. Soittotunnin aikaa voi muuttaa yhdessä opettajan kanssa sopimalla.</p>
        </div>
        </div>
        <div className="accordion-item">
          <h4>Mitä eroa on taiteen perusopetuksella ja avoimen musiikkiopiston opetuksella?</h4>
        <div className="content-faq">
          <p>Taiteen perusopetus on järvenpääläisille ja tuusulalaisille lapsille ja nuorille suunnattua tavoitteellista musiikin opiskelua, joka noudattaa opetussuunnitelmaa ja jota valtio tukee. Taiteen perusopetuksen oppilapaikkoja on rajallinen määrä. Opintoihin kuuluu soitto- tai laulutuntien ja kotiharjoittelun lisäksi myös yhteismusisointia ja musiikin hahmotusaineiden opintoja.</p>
          <br></br>
          <p>Avoimessa musiikkiopiston oppilaaksi voi ilmoittautua kuka tahansa myös muiden kuntien alueelta ja oppilas voi itse määritellä opintojensa tavoitteet. Avoimessa musiikkiopistossa voi keskittyä oman instrumentin opiskeluun tai osallistua ryhmäopintoihin, kuten vaikkapa kuoroon tai bändisoittoon.</p>
        </div>
      </div>
       <div className="accordion-item">
          <h4>Voiko lapseni osallistua koulumusikanttiopetukseen vain omalla koulullaan?</h4>
       <div className="content-faq">
         <p>Koulumusikanttiopetukseen ei tarvitse osallistua juuri omalla koululla, vaan ryhmään voi ilmoittautua millä tahansa itselle sopivalla koululla.</p>
       </div>
       </div>
        <div className="accordion-item">
          <h4>Voinko tulla mukaan pelkkään kuoroon tai orkesteriin?</h4>
        <div className="content-faq">
          <p>Kyllä voi, musiikkiopiston kuorot, bändit ja orkesterit ottavat mielellään vastaan uusia soittajia. Bändeihin ja orkestereihin voi ilmoittautua mukaan opettajan tai koesoiton kautta. Kysy tästä lisää toimistolta <span><a href="mailto:musiikkiopisto@jarvenpaa.fi">musiikkiopisto@jarvenpaa.fi</a></span></p>
        </div>
       </div>
     </div>
    
     <br></br>
     <h4>Oppilaaksi hakeutuminen:</h4>
    <div  className="accordion">
      <div className="accordion-item">
       <h4>Miten ilmoittaudun oppilaaksi?</h4>
      <div className="content-faq">
        <p>Ilmoittautuminen tapahtuu sähköisesti musiikkiopiston sivuilta <span><a href="https://kum.eepos.fi/#/splash">kum.eepos.fi</a></span></p>
      </div>
      </div>
      <div className="accordion-item">
        <h4>Onko teillä pääsykokeet?</h4>
      <div className="content-faq">
          <p>Musiikkiopistossa ei ole pääsykokeita, vaan oppilaat otetaan ilmoittautumisjärjestyksessä vapaille oppilaspaikoille.</p>
      </div>
      </div>
      <div className="accordion-item">
        <h4>Olen varasijalla, mitä se tarkoittaa?</h4>
      <div className="content-faq">
          <p>Kun olet varasijalla, olet jonossa odottamassa oppilaspaikkaa. Oppilaita otetaan oppilaaksi soitinkohtaisessa ilmoittautumisjärjestyksessä vapaille oppilaspaikoille. Lukuvuoden alussa ja lukukausien vaihteessa vapautuu usein oppilaspaikkoja, jotka täytetään varasijalla olevilla oppilailla. Oppilaita otetaan musiikkiopistoon siis pitkin lukuvuotta sitä mukaa, kun paikkoja vapautuu.</p>
      </div>
      </div>
      </div>

      <br></br>
      <h4>Musiikkiopiston oppilaana:</h4>
     <div  className="accordion">
       <div className="accordion-item">
        <h4>Mistä saan Eepos-tunnukset?</h4>
       <div className="content-faq">
          <p>Eepos-tunnukset saat musiikkiopiston toimistosta <a href="mailto:musiikkiopisto@jarvenpaa.fi">musiikkiopisto@jarvenpaa.fi</a></p>
       </div>
       </div>
       <div className="accordion-item">
         <h4>Miten jatkoilmoittaudun?</h4>
       <div className="content-faq">
              <ul>
               <li>Jatkoilmoittautuminen tehdään kirjautumalla oppilaan tunnuksilla Eepokseen osoitteessa:<a href="https://kum.eepos.fi/#/splash">http://kum.eepos.fi</a></li>
               <li>Uudet oppilaat saavat kirjautumistunnukset opintojen aloittamisen yhteydessä Jos tunnuksia ei syystä tai toisesta löydy, voit olla yhteydessä toimistoon <a href="mailto:musiikkiopisto@jarvenpaa.fi">musiikkiopisto@jarvenpaa.fi</a> </li>
            </ul>  
             <div className="testi-sub">
               <ul className="sub-list">
                 <li>Valitse etusivun yläosasta Ilmoittaudu-painike.</li>
                 <li>Ohjelma voi pyytää sinua (keltaisessa laatikossa avautuvan sivun yläosassa) tarkistamaan yhteystietosi. Tässä tapauksessa seuraa ohjelman antamia ohjeita. Voit samalla vahvistaa sähköpostiosoitteesi Eepos-tunnuksesi myöhempää palauttamista varten.</li>
                 <li>HUOM! Eepoksen käyttö tapahtuu aina suojatussa yhteydessä, eikä tietoja luovuteta kolmansille osapuolille.</li>
                 <li>Valitse JATKOILMOITTAUTUMINEN taiteen perusopetukseen -lomakkeen oikeasta reunasta  ilmoittaudu-painike.</li>
                 <li>Täytä ilmoittautumislomake ja valitse lopuksi sivun alareunasta SEURAAVA. Tarkista vielä yhteenveto ja klikkaa lopuksi LÄHETÄ.</li>
                 <li>Saat onnistuneesta ilmoittautumisesta vahvistusviestin sähköpostiisi.</li>


               </ul>
             </div>  
       </div>
       </div>
       <div className="accordion-item">
         <h4>Mistä tiedän, mihin orkesteriin tms. ryhmään voin ilmoittautua?</h4>
       <div className="content-faq">
           <p>Oma opettaja osaa parhaiten neuvoa sopivan ryhmän valinnassa. Opettaja on voinut ehdottaa tulevan kauden ryhmää myös suoraan ilmoittautumislomakkeelle, ja siinä tapauksessa se näkyy Eepoksen lomakkeella vihreällä. Voit silti itse valita, mihin ryhmään ilmoittaudut. Sinulle on voitu ehdottaa esimerkiksi kaikkia tietyn muha-tason ryhmiä, jolloin voit valita aikatauluusi parhaiten sopivan ryhmän. Orkestereiden osalta kehotamme ensisijaisesti noudattamaan oman opettajan suositusta. Jos suositusta ei näy, niin ei hätää, opettaja neuvoo mihin ryhmään kannattaa mennä.</p>
       </div>
       </div>
       <div className="accordion-item">
          <h4>Mitä muha on ja miksi sitä opiskellaan?</h4>
          <div className="content-faq">
             <p>Muhan eli musiikin hahmotusaineiden tunneilla harjoitellaan mm. nuotinlukua, musiikkitietoutta ja säveltapailua. Muha-opinnot tukevat instrumenttiopintoja ja siksi ne sisältyvät taiteen perusopetuksen opintoihin. Opinnot aloitetaan yleensä noin 10 ikävuoden vaiheilla muha 1-ryhmässä. Musiikin hahmotusaineiden sisältöalueet ovat luovuus, käytännön muusikkous, improvisointi ja yleinen musiikin hahmottaminen.</p>
          </div>
        </div>
        <div className="accordion-item">
        <h4>Miten opintojen peruminen tapahtuu?</h4>
          <div className="content-faq">
             <p>Huoltaja / täysi-ikäinen oppilas ilmoittaa opintojen lopettamisesta sähköpostitse musiikkiopiston toimistoon <span><a href="mailto:musiikkiopisto@jarvenpaa.fi">musiikkiopisto@jarvenpaa.fi.</a></span>  Opinnot kirjataan loppuneiksi sähköpostin lähetyspäivästä alkaen. Myös opettajalle on hyvä kertoa lopettamisesta, mutta opettajalle ilmoittaminen ei riitä lopetusilmoitukseksi.
             </p>
          </div>
         </div>
       </div>
       <br></br>
       <h4>Oma soitin:</h4>
      <div  className="accordion">
        <div className="accordion-item">
         <h4>Täytyykö oppilaalla olla heti oma soitin?</h4>
        <div className="content-faq">
           <p>Omaa soitinta ei tarvitse olla heti opintojen alkaessa. Oma opettaja osaa parhaiten neuvoa soittimen hankkimisessa. Musiikkiopistolla on lainasoittimia, joita on mahdollista vuokrata edullisesti opettajan kautta. Lainapianoja musiikkiopistolla ei ole. </p>
        </div>
        </div>
        <div className="accordion-item">
          <h4>Pitääkö piano-oppilaalla olla piano, vai riittääkö, että kotona on sähköpiano/keyboard?</h4>
        <div className="content-faq">
            <p>Piano-oppilaalla olisi hyvä olla piano. Soittimen hankinnassa osaa parhaiten opastaa oma opettaja.</p>
        </div>
        </div>
      
        </div>
        <br></br>
        <h4>Lukukausimaksut:</h4>
       <div  className="accordion">
         <div className="accordion-item">
          <h4>Miten lukukausimaksu maksetaan?</h4>
         <div className="content-faq">
            <p>Lukukausimaksut laskutetaan Järvenpään kaupungilta tulevalla laskulla joko postitse tai sähköisenä verkkolaskuna. Syyslukukauden laskut lähetetään loka-marraskuussa ja kevätlukukauden laskut helmi-maaliskuussa. </p>
         </div>
         </div>
         <div className="accordion-item">
           <h4>Perheestäni on monta oppilasta musiikkiopiston oppilaina. Saammeko alennusta?</h4>
         <div className="content-faq">
             <p>Alennusta myönnetääntaiteen perusopetuksen oppilaille sekä muskarilaisille 50% lukukausimaksusta perheen kolmannesta lapsesta lukien.</p>
         </div>
         </div>
         <div className="accordion-item">
         <h4>Voinko saada laskulleni lisää maksuaikaa?</h4>
            <div className="content-faq">
              <p>Kyllä voit. Sovi maksujärjestelystä ottamalla yhteyttä musiikkiopiston toimistoon <span><a href="mailto:musiikkiopisto@jarvenpaa">musiikkiopisto@jarvenpaa.fi.</a></span></p>
           </div>
         </div>
         <div className="accordion-item">
         <h4>Voinko saada maksuhuojennusta ja miten sitä haetaan?</h4>
          <div className="content-faq">
             <p>Keskisen Uudenmaan Musiikkiopisto myöntää maksuhuojennuksia taiteen perusopetuksen oppilaille taloudellisin perustein. Maksuhuojennusta haetaan syyslukukauden alussa annettuun määräaikaan mennessä täyttämällä hakemus Eepoksessa sekä toimittamalla pyydetyt liitteet määräaikaan mennessä. Yksi hakemus riittää per perhe</p>
          </div>
         </div>
         <div className="accordion-item">
         <h4>Oppilas on ollut paljon sairaana, voimmeko saada alennusta tai korvaavia opetuskertoja?</h4>
           <div className="content-faq">
             <p>Opettajalla ei ole velvollisuutta korvata oppilaan perumia opetuskertoja. Poissaolot sairastumisen vuoksi voidaan hyvittää lääkärintodistusta vastaan. Omavastuu sairasloman ajalta on yksi kuukausi. Asiasta päättää tapauskohtaisesti rehtori.</p>
          </div>
        </div>
        <div className="accordion-item">
        <h4>Opettajamme on joutunut perumaan opetustaan monta kertaa, saammeko hyvitystä?</h4>
         <div className="content-faq">
            <p>Jos opettaja on joutunut sairauden tai muun syyn takia peruuttamaan useamman opetuskerran, opetuskerrat pyritään järjestämään korvaavana aikana tai opettajalle hankitaan sijainen. Jos korvaavaa opetusta ei voida järjestää, sovitaan lukukausimaksun hyvityksestä. Lukukauden ensimmäistä poissaolokertaa ei korvata.</p>
         </div>
        </div>
       
         </div>
         <br></br>
         <h4>Muita kysymyksiä:</h4>
        <div  className="accordion">
          <div className="accordion-item">
           <h4>Voimmeko tilata kauttanne esiintyjän juhliimme?</h4>
          <div className="content-faq">
              <p>Musiikkiopistolta voi tiedustella esiintyjiä juhliin ja tapahtumiin. Muistathan kuitenkin, että suurin osa oppilaistamme on lapsia ja nuoria, ja vielä harjoittelemassa sekä instrumentinhallintaa että esiintymistä. Esitykset voivat olla esimerkiksi 2-5 kappaleen mittaisia, eikä esitysajankohta voi olla kovin myöhään illalla. </p>
              <p>Lomakkeen ja muuta infoa löydät <a href="/esiintyjävälitys">täältä</a></p>

          </div>
          </div>
       
          </div>
          <br></br>
   
  
    </div>
    )
}

export default Faq2;