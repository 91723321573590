
import React, { useEffect, useState } from 'react';
//import './opinnot.css';
import Axios from 'axios'
import ReactMarkdown from 'react-markdown';
import './konsertit.css'
//import { exception } from 'react-ga';
//import Kuva from '../images/demo.jpg'

//const httpsKeikat = 'https://kumstrapi.xyz/testis'
//const httpKeikat =  'http://52.208.227.115/testis'

const testiKeikat = [
    {
      "id": 620,
      "name": "Marin oppilaiden konsertti",
      "description": "",
      "location": "Järvenpään seurakuntasali",
      "start_date": "2024-10-03",
      "end_date": "2024-10-03",
      "start_time": "18:30:00",
      "end_time": "20:00:00",
      "category_id": 17,
      "category_name": "Konsertit syys 2024",
      "room": "",
      "floor": "",
      "building": "",
      "instances": [
        {
          "title": "Gluck: Menuetti",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Somewhere over the rainbow",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Can you feel the love tonight",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Merry go round",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Mozart: Turkkilainen rondo",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Gossec: Gavotti",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Faure: Berceuse",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Mendelsson: Kevätlaulu",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Venetsian karnevaalit",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Gossec. Tambourin",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Gluck: Melodie",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Marcello: Sonaatti d 1. ja 2. osa",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "J-P Lehto:Fuusio ja Gluck: Konsertto G 1.osa",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Pergolesi: Konsertto G 3.osa",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Hannikainen: Kevätaamu",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        },
        {
          "title": "Päivänsäde ja menninkäinen ja Sibelius: Andante festivo",
          "misc_text": "",
          "start_date": "2024-10-03",
          "end_date": "2024-10-03",
          "start_time": null,
          "duration": "0.00",
          "is_break": false
        }
      ]
    },
    {
      "id": 611,
      "name": "Piristystä perjantaihin",
      "description": "",
      "location": "Järvenpään kirjasto",
      "start_date": "2024-10-04",
      "end_date": "2024-10-04",
      "start_time": "17:00:00",
      "end_time": "18:00:00",
      "category_id": 17,
      "category_name": "Konsertit syys 2024",
      "room": "",
      "floor": "",
      "building": "",
      "instances": [
        {
          "title": "John Williams: The Chamber of Secrets",
          "misc_text": "",
          "start_date": "2024-10-04",
          "end_date": "2024-10-04",
          "start_time": null,
          "duration": "3.00",
          "is_break": false
        },
        {
          "title": "J. Massenet: Meditation",
          "misc_text": "",
          "start_date": "2024-10-04",
          "end_date": "2024-10-04",
          "start_time": null,
          "duration": "5.00",
          "is_break": false
        },
        {
          "title": "J. Moser: Funky Piano",
          "misc_text": "",
          "start_date": "2024-10-04",
          "end_date": "2024-10-04",
          "start_time": null,
          "duration": "2.00",
          "is_break": false
        },
        {
          "title": "Don King: Alley Cat Blues",
          "misc_text": "",
          "start_date": "2024-10-04",
          "end_date": "2024-10-04",
          "start_time": null,
          "duration": "3.00",
          "is_break": false
        }
      ]
    },
    {
      "id": 623,
      "name": "Esiintymispraktikum",
      "description": "",
      "location": "Monion Riihisali",
      "start_date": "2024-10-04",
      "end_date": "2024-10-04",
      "start_time": "18:00:00",
      "end_time": "20:00:00",
      "category_id": 17,
      "category_name": "Konsertit syys 2024",
      "room": "",
      "floor": "",
      "building": "",
      "instances": []
    },
    {
      "id": 593,
      "name": "Soivat syysillat kahvilassa",
      "description": "",
      "location": "Keudan Paatela, 2.krs",
      "start_date": "2024-10-09",
      "end_date": "2024-10-09",
      "start_time": "17:00:00",
      "end_time": "20:00:00",
      "category_id": 17,
      "category_name": "Konsertit syys 2024",
      "room": "",
      "floor": "",
      "building": "",
      "instances": [
        {
          "title": "M. Giuliani: Etydi a Allegro, D.King: Alley Cat Blues, J. Zenamon: Escalando,",
          "misc_text": "",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": "18:00:00",
          "duration": "6.00",
          "is_break": false
        },
        {
          "title": "18.45- 19.00 Lapsikuoro ja Outin lauluoppilaita",
          "misc_text": "",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "15.00",
          "is_break": false
        },
        {
          "title": "Klo 19.00-19.25 Sadun laulajia",
          "misc_text": "Klo 19.00-19.25",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "25.00",
          "is_break": false
        },
        {
          "title": "Kristiinan sellistejä 18-18.20",
          "misc_text": "",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "20.00",
          "is_break": false
        },
        {
          "title": "klo 17-17.20  Sadun oppilaita",
          "misc_text": "",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "20.00",
          "is_break": false
        },
        {
          "title": "Klo 17.20-17.30",
          "misc_text": "Johanna E: n oppilaita",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "10.00",
          "is_break": false
        },
        {
          "title": "17.30-17.40 Elsin klarinetisteja",
          "misc_text": "",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "10.00",
          "is_break": false
        },
        {
          "title": "J.S.Bach Menuetti No 3, Menuetto No",
          "misc_text": "klo 17.40",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "3.00",
          "is_break": false
        },
        {
          "title": "Goltermann: Rondo. J.S.Bach: Arioso",
          "misc_text": "Klo 17.45",
          "start_date": "2024-10-09",
          "end_date": "2024-10-09",
          "start_time": null,
          "duration": "8.00",
          "is_break": false
        }
      ]
    }]

const Konsertit = () => {
    //const [ test , setTest ] = useState("")
    const [errorMessage, setErrorMessage] = useState("Loading ....")
    const [ events , setEvents ] = useState([])
      
    useEffect(() => {
          (async() => {
              //const keikat = await Axios.get('http://52.208.227.115/testis');
              //const keikat = await Axios.get(httpsKeikat);
              //setTest(keikat.data.map(x => x.info))
              //console.log(keikat.data.map(x => x.info))
            
            try {  
                const listOfEvents = await Axios.get("/api/kum/konsertit");
                setEvents(listOfEvents.data)
            } catch (e) {
                setEvents([])
                setErrorMessage("Konsertit")
            }
            const items = document.querySelectorAll(".konsertit-accordion h4");
            items.forEach(item => item.addEventListener('click', toggleAccordion));       
              
          })()
          
        }, [])
      
    function toggleAccordion(){
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('active');        
    }
    const ShowText = () => {
        
        if (test[0] != null){
        return(
            <div className='main-konsertti'>
            <div className='konsertit-main'>
                 <h2 className="container" style={{textAlign: "center", marginTop: "50px", marginBottom: "20px", fontWeight: "300", textDecoration:"none", fontSize: "50px"}}>Tulevat konsertit</h2>     

            </div>
            <div className='konsertit container'>
            <div  className="style-mark">    
           
                <ReactMarkdown className='mark' children={test[0]} /> 
            </div>
            </div>
            </div>
        )
        } else {
        //console.log(viikonLoppu[0].info)
        return(
            <div style={{ height: "80vh"}}>
                 <p>Kursseja ladataan</p>
            </div>
           
        )
        }
    }

    const ShoeEvents = () => {
        if (events.length !== 0){;
            return(
                <div className='main-konsertti'>
                    <div className='konsertit-main'>
                        <h2 className="konsertit-h2 container" >{testiKeikat[0]['category_name']}</h2>     
    
                    </div>
                    <div className='konsertit-accordion container'>
                        {events.map((x, index) =>
                            <div key={x.id}  className='konsertit-accordion-item'>
                                    <h4 style={{ display:'flex', flexDirection: "column" }}>
                                        <strong>{x.start_date} {x.name}</strong> 
                                        <p>{x.location} / klo:{x.start_time.slice(0,5)}-{x.end_time.slice(0,5)}</p>  
                                    <h2></h2>
                                </h4>
                                    
                                    
                                <div className='konsertit-content-faq'>
                                    <><p>Ohjelmisto:</p></>
                                    {x['instances'].map((data, index) => 
                                        <div className='konsertit-setlist'>
                                            <p>{index + 1}.</p><p>{data.title}</p>
                                        </div>

                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        } else {
            return(
                <div style={{ height: '100vh', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className='konsertit-error'>
                        <h2>{errorMessage}</h2>
                        <p>Ohjelmisto päivityy pian ...</p>
                    </div>
                </div>
            )
        } 
    }

    return(
        <div>
            <div className="">
                    {/*<ShowText />   */}
                    <ShoeEvents />
            </div>
         
        </div>
    )
}

export default Konsertit;