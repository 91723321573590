import React from 'react'
import './footer.css'


const Footer = () => {

    return(
      
        <div className="footer"> 
          <div className="footer-rest">
              <div className="footer-site">
                <h3>Menu</h3>
                  <br></br>
                    <ul className="main-menu">
                        <li><a href="/opiskelu" id="2">Opinnot</a></li>
                        <li><a href="/hinnastoperusopetus" id="3">Hinnasto ja peruutukset</a></li>
                        {/* <li><a href="/soittimet">Soittimet</a></li> */}
                        <li><a href="/esiintyjävälitys">Esiintyjävälitys</a></li>
                        <li><a href="/muskari">Muskari</a></li>
                        <li><a href="/ajankohtaiset/firstpage" id="4">Ajankohtaiset</a></li>
                        <li><a href="/toimipisteet" id="4">Toimipisteet / yhteystiedot</a></li>
                        <br></br>
                        <li><a href="/saavutettavuusseloste">Saavutettavuusseloste</a></li>
                    </ul>  
              </div>
              <div className="footer-contacts">
                  <h3>Yhteystiedot</h3>
                  <br></br> 
                      <h4>Rehtori</h4>
                      <p>Sauli Tyni</p>
                      <p>puh. 040 315 2845 </p>
                      <p>sauli.tyni(at)jarvenpaa.fi </p>
                  <br></br>
                      <h4>Apulaisrehtori</h4>
                      <p>Juha-Pekka Putkonen</p>
                      <p>puh. 040 315 2864</p>
                      <p>juha-pekka.putkonen(at)jarvenpaa.fi</p>
                  <br></br>
                      <h4>Toimisto</h4>
                      <p>puh. 040 315 2803 </p>
                      <p>musiikkiopisto(at)jarvenpaa.fi</p>
                      <p>Puhelinaika</p>
                      <p>ma-to klo 13-16.00</p>
              </div>
              
              <div className="footer-extras">
                <h3>Seuraa meitä myös</h3>
                <br></br>
                <div className="social">
                    <a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="blank"><i className="fa fa-facebook fa-3x"></i></a>
                    <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank" ><i className="fa fa-instagram fa-3x"></i></a>
                    <a href="https://www.youtube.com/channel/UC2y2uuOWJqPspM7zHAK9Bvw" target="blank" ><i className="fa fa-youtube fa-3x"></i></a>
                </div>
              </div>    
        </div>
      </div>
    )
}

export default Footer;